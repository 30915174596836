import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './miinformacion.dialog.html',
  styleUrls: ['./miinformacion.dialog.css'],

})
export class MiInformacionDialog {
  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;
  apiUrl = "";

  constructor(private builder: FormBuilder,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    public dialogRef: MatDialogRef<MiInformacionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.apiUrl = environment.dataServiceUrl + "/Identity/Usuario/miinformacion"
  }

  ngOnInit() {
    const correoInput = document.getElementById('correo') as HTMLInputElement;
    const telefonoInput = document.getElementById('telefono') as HTMLInputElement;
    const usuario = document.getElementById('usuario') as HTMLSpanElement;
    //correoInput.disabled = true;
    //telefonoInput.disabled = true;

    const guardar = document.getElementById('guardar') as HTMLButtonElement;
    const salir = document.getElementById('salir') as HTMLButtonElement;

    telefonoInput.addEventListener('input', (event) => {
      telefonoInput.value = telefonoInput.value.replace(/[^0-9]/g, '');
    });

    guardar.addEventListener('click', () => {
      let correo = correoInput.value;
      let telefono = telefonoInput.value;

      const correoPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!correoPattern.test(correo)) {
        this.openNotificationDanger("Correo electronico no valido");
        return; 
      }

      fetch(`${this.apiUrl}/guardar`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify({ correo, telefono })
      })
        .then(response => response.json())
        .then(resultado => {
          if (resultado.codigo === 200) {
            this.dialogRef.close({
              data: ""
            });
          } else {
            this.openNotificationDanger(resultado.mensaje);
          }
        })
        .catch(error => {
          console.error('Error al realizar la acción:', error);
        });
    });

    salir.addEventListener('click', () => {
      this.dialogRef.close({
        data: ""
      });
    });

    fetch(`${this.apiUrl}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
      }).then(response => response.json())
      .then(resultado => {
        correoInput.value = resultado.correo;
        telefonoInput.value = resultado.telefono;
        usuario.innerText = resultado.usuario;

        if (resultado.modCorreo === 1) {
          correoInput.disabled = false;
        } else {
          correoInput.disabled = true;
        }
        if (resultado.modTelefono === 1) {
          telefonoInput.disabled = false;
        } else {
          telefonoInput.disabled = true;
        }
      }).catch(error => {
        console.error('Error al cargar los datos:', error);
      });

  }

  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }
}
