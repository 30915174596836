import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  show: Boolean = false;

  constructor() { }

  showLoading() {
    this.show = true;
  }

  hideLoading() {
    this.show = false;
  }
}
