import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap} from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class ProcesosAutomaticosService {
    
    constructor(private http: HttpClient) {

    }

    getModulosList(val:any): Observable<any> {
        let params = new HttpParams();
        params = params.append('filtroValor', val || '');
        params = params.append('filtroCondicion', "like");
        params = params.append('filtroColumna', 'modulo_nombre');
        params = params.append('filtroRelacion', 'and');    
        
        params = params.append('filtroValor', '2');
        params = params.append('filtroCondicion', "=");
        params = params.append('filtroColumna', 'destino');
        params = params.append('filtroRelacion', 'or'); 

        params = params.append('filtroValor', '4');
        params = params.append('filtroCondicion', "=");
        params = params.append('filtroColumna', 'destino');

        params = params.append('paginaTamano', '10');
        params = params.append('paginaNumero', '1');
        params = params.append('ordenColumna', 'modulo_nombre');
        params = params.append('ordenTipo', 'asc');
      
        const sUrl = `${environment.dataServiceUrl}/Identity/Modulos/Pagina`;

        return this.http.get<any>(sUrl, { params }).pipe(
            tap(row => this.log('fetched ModulosList')),
            catchError((error) => this.handleError('getModulosList', error))
        );
    }

    getProcesosAutomaticos(formulario: any): Observable<any> {             
        let url = `${environment.dataServiceUrl}/${formulario.pathModulo}/ProcesosAutomaticos/UltimaEjecucion`;

        return this.http.get<any>(url).pipe(
            tap(row => this.log('fetched LogCargueMasivoPQR')),
            catchError((error) => this.handleError('getLogCargueMasivoPQRList', error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

        // TODO: send the error to remote logging infrastructure
        console.error(result.error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${result.message}`);

        // Let the app keep running by returning an empty result.
        return of(result);
    }
    /** Log a dw_wfc_carpeta_maestro_detalleService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`dw_wfc_carpeta_maestro_detalleService: ${message}`);
        console.log(`dw_wfc_carpeta_maestro_detalleService: ${message}`);
    }

}
