import { Component, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'videoplayer-component',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.css'],
})
export class VideoPlayerComponent {
  @ViewChild('vid', { static: false }) audioPlayer?: ElementRef<HTMLMediaElement>;

  _proc = false;
  src: any = null;
  srcImg: any = null;
  srcVid: any = null;
  verVideo: boolean = false;
  verImagen: boolean = false;
  constructor(public dialogRef: MatDialogRef<any>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    let extension = data.content.substring(data.content.lastIndexOf('.'));

    if (extension == ".mp4" || extension == ".mov" || extension == ".webm") {
      this.srcImg == null;
      this.verVideo = true;
      this.loadVideo(data.fileB);
    } else if (extension == ".jpg" || extension == ".png") {
      this.srcVid == null;
      this.verImagen = true;
      this.loadImg(data.fileB);
    }

    this.loadVideo(data.fileB);
  }

  async loadVideo(fileB) {
    const video: any = document.getElementsByTagName('video');
    this.srcVid = window.URL.createObjectURL(fileB);
    this.srcVid = this.sanitizer.bypassSecurityTrustUrl(this.srcVid);
    video.src = this.srcVid;
    video.autoplay = true;
    video.controls = true;
    try {
      await this.audioPlayer.nativeElement.play();
    } catch (err) {
      await this.audioPlayer.nativeElement.play();
    }
  }

  async loadImg(fileB) {
    this.srcImg = window.URL.createObjectURL(fileB);
    this.srcImg = this.sanitizer.bypassSecurityTrustUrl(this.srcImg);
  }

}
