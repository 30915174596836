import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
  selector: 'app-alertas-confirm',
  templateUrl: './alertasConfirm.component.html',
  styleUrls: ['./alertas.component.css']
})
export class AlertasConfirmComponent implements OnInit {

  tipoMensaje: number;
  descMensaje: string;
  titulo: string;

  constructor(private bottomSheetRef: MatBottomSheetRef<AlertasConfirmComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
        switch (data.tipoMensaje) {
          case 1:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeConfirmacion'; break;
          case 2:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeConvenio'; break;
          case 3:  this.tipoMensaje = 3; this.descMensaje = 'alertas._mensajeDescargar';break;
          case 4:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeUsuario';break;
          case 5:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeFechas'; break;
          case 6:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePlanoSalida';break;
          case 7:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePlanoSalidaError'; break;
          case 8:  this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeCierreInconsistencia'; break;
          case 9:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeReasignacionProcesos'; break;
          case 10: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeArchivoEnteExterno'; break;
          case 11: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeCambioPlanPagos';break;
          case 12: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeReabrirProceso'; break;
          case 13: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidarIdentida';break;
          case 14: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeClienteValidado';break;
          case 15: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeProcesoFinalizado';break;
          case 16: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeProcesoFinalizado';break;
          case 17: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesoMasivoPagos'; break;
          case 18: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePeriodoPagaduria'; break;
          case 19: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePeriodoPagConsulta'; break;
          case 20: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeFinProcesoMasivoPagos';break;
          case 21: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajePeriodoPagFecha';break;
          case 22: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAnularPago'; break;
          case 23: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAplicarPago';break;
          case 24: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesoMasivoCierre';break;
          case 25: this.tipoMensaje = 5; this.descMensaje = 'alertas._mensajeDescargar';break;
          case 26: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeDiasExpiracion'; break;
          case 27: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeCancelacionMasivaFolios';break;
          case 28: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeSinCompraCartera'; break;
          case 29: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeSinCompraCarteraLiquidar'; break;
          case 30: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEnviarSTP';break;
          case 31: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEnviarPLANO';break;
          case 32: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeValidarPreguntas'; break;
          case 33: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAutorizacion'; break;
          case 34: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAnalisisAprobación'; break;
          case 35: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeCirculoCredito'; break;
          case 36: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidaPLD';break;
          case 37: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeReserva';break;
          case 38: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeReenvioStp'; break;
          case 39: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeGenerarPlanoBanxico';break;
          case 40: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeValidarBanxico'; break;
          case 41: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidarCorreoDesembolso';break;
          case 42: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeCargaArchivo'; break;
          case 43: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeSolicitudDocumentos'; break;
          case 44: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeRecepcionFolios'; break;
          case 45: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeGestionDocumentos'; break;
          case 46: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAnulacionFolios'; break;
          case 47: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAprobacionCampana'; break;
          case 48: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidarAsocPlantilla';break;
          case 49: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeValidarAprobacion';break;
          case 50: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeClasificarCampana'; break;
          case 51: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesarCampana'; break;
          case 52: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeAprobacionGeneracion'; break;
          case 53: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAprobarEnvioMasivo'; break;
          case 54: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAnularCampana'; break;
          case 55: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEnviarMensajeMasivo'; break;
          case 56: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeNoProcesarCampana'; break;
          case 57: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeValidarSinProcesarCampana'; break;
          case 58: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProcesadoScoreCampana'; break;
          case 59: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeProcesadoScore'; break;
          case 60: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeProcesadoCampana'; break;
          case 61: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAplicaFiltroCampana'; break;
          case 62: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAprobadoEnvioCampana'; break;
          case 63: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeEjecutaEnvioCampana'; break;
          case 64: this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajeAnulaCampana'; break;
          case 65: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeEjecucionArchivoReserva'; break;
          case 66: this.tipoMensaje = 2; this.descMensaje = 'alertas._mensajeBloqueoQuebranto'; break;
          case 67:  this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeConfirmacionTasaE'; break;
          case 68:  this.tipoMensaje = 6; this.descMensaje = 'alertas._mensajeSeguroContinuar'; break;
          case 69:  this.tipoMensaje = 4; this.descMensaje = 'alertas._mensajePoliticasPendientes'; break;
          case 70:  this.tipoMensaje = 2 ; this.descMensaje = 'alertas._mensajeEstadoCarpeta'; break;
          case 71:  this.tipoMensaje = 2 ; this.descMensaje = 'alertas._mensajeDocumentosFase'; break;
          case 72:  this.tipoMensaje = 2 ; this.descMensaje = 'alertas._mensajePagaduria'; break;
          case 73:  this.tipoMensaje = 2 ; this.descMensaje = 'alertas._mensajeAcceso'; break;
          case 74:  this.tipoMensaje = 2 ; this.descMensaje = data.mensaje; break;
          case 75:  this.tipoMensaje = 2 ; this.descMensaje = 'alertas._mensajeCompraDeuda'; break;
          case 76:  this.tipoMensaje = 2 ; this.descMensaje = 'alertas._mensajeSeleccionPoliticas'; break;
          case 78: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeReporteClientes';break;
          case 79: this.tipoMensaje = 1; this.descMensaje = 'alertas._mensajeProceLicita';break;
          default: break;
        }
      }

  ngOnInit() {
    this.titulo = this.data;
  }

  btnProceso(tipo: string): void {
    this.bottomSheetRef.dismiss({
      mensaje: tipo
    })
  }
}
