import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
	selector: 'app-horario',
	templateUrl: './horario.component.html',
})
export class HorarioComponent implements OnInit {
	public actual: string;
	horarioForm: FormGroup;

	_errorHora: boolean = false;
	_errorMinutos: boolean = false;

	get validarBoton() {
		return this.horarioForm.invalid || this._errorHora || this._errorMinutos
	}

	constructor(private builder: FormBuilder,
				public dialogRef: MatDialogRef<HorarioComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		
		this.actual = data.horario;
	}

	ngOnInit() {
		this.horarioForm = this.builder.group({
			'hora': ['', [Validators.required, Validators.maxLength(2), Validators.pattern('^[0-9]+')]],
			'minutos': ['', [Validators.required, Validators.maxLength(2), Validators.pattern('^[0-9]+')]]
		}, {
			validators: (formGroup: FormGroup): ValidationErrors | null => {
				const data = formGroup.getRawValue();
				let validationErrors = {};
				return validationErrors;
			}
		});

		if (this.actual != null) {
			let str = this.actual.split(':')
			this.horarioForm.controls.hora.reset(str[0]);
			this.horarioForm.controls.minutos.reset(str[1]);
		};

		this.horarioForm.controls.hora.valueChanges.subscribe(hora => {
			if (hora) {
				if (Number(hora) === 24) {
					this._errorHora = Number(this.horarioForm.controls.minutos.value) !== 0;
				} else {
					this._errorHora = Number(hora) > 23;
				};
			};
		})

		this.horarioForm.controls.minutos.valueChanges.subscribe(minutos => {
			if (minutos) {
				if (Number(this.horarioForm.controls.hora.value) === 24) {
					this._errorMinutos = Number(minutos) !== 0;
				} else {
					this._errorMinutos = Number(minutos) > 59;
				};
			};
		})
	}

	onSubmit(formData: any) {
		let horario = {
			hora: Number(formData.hora) < 10 ? String(`0${Number(formData.hora)}`) : String(formData.hora),
			minutos: Number(formData.minutos) < 10 ? String(`0${Number(formData.minutos)}`) : String(formData.minutos)
		};
		
		this.dialogRef.close({
			horario: horario
		})
	}
}
