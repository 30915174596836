import { Component, OnInit } from '@angular/core';
import { RecuperarAccesoService } from './recuperaracceso.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatSnackBar, MatBottomSheet } from '@angular/material';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'recuperar-acceso',
  templateUrl: './recuperaracceso.html',
  styleUrls: ['./recuperaracceso.css'],
  providers: [RecuperarAccesoService]
})
export class RecuperarAccesoComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  token: string;
  _proc = false;
  cambioClaveForm: FormGroup;
  info: any = {};
  recovered = false;
  mensajeRecuperado = '.';

  constructor(public dialog: MatDialog,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    private recuperarAccesoService: RecuperarAccesoService) {
  }

  ngOnInit() {
    this.cambioClaveForm = this.builder.group({
      'subject': [''],
      'passwordnew': ['', [Validators.required]],
      'confirmpasswordnew': ['', [Validators.required]],
    }, {
      validator: this.checkPasswords
    });
    this.route
      .queryParamMap.subscribe((params: any) => {
        this.token = params.params.info;
        sessionStorage.setItem('token', this.token);
        this.info = jwt_decode(sessionStorage.getItem('token'));
        this.cambioClaveForm.patchValue({ subject: this.info.sub });
      });
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('passwordnew').value;
    const confirmPass = group.get('confirmpasswordnew').value;
    return pass === confirmPass ? null : { notSame: true };
  }
  onSubmit() {
    this._proc = true;
    this.recovered = false;
    this.mensajeRecuperado = '...';
    if (this.cambioClaveForm.valid) {
      this.recuperarAccesoService.cambioClave(this.cambioClaveForm.value).subscribe((data: any) => {
        this._proc = false;
        if (data.status === 500) {
          this.openNotificationInfo('Recuperación de Acceso Caducada - Realizar el Proceso de Recuperación Nuevamante');
        } else {
          if (data.body.estado === 200) {
            this.recovered = true;
            this.mensajeRecuperado = data.body.Mensaje;
          } else {
            if (data.message) {
              this.openNotificationDanger(data.message);
            } else if (data.body.mensaje) {
              this.openNotificationDanger(data.body.mensaje);
            } else {
              this.openNotificationDanger('Error');
            }
          }
        }
      });
    }
  }
  ingresar() {
    this.router.navigateByUrl('');
  }
  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }

  openNotificationInfo(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 8000,
      panelClass: 'infoSnackBar',
    });
  }

}
