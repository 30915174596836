import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class GestionAvanceProcesosService {

    constructor(private http: HttpClient) {}

    getAvanceProcesosAll(filtro: any, destino: string): Observable<any> {

        let url = `${environment.dataServiceUrl}/${destino}/dw_adm_consulta_avance_procesos/Recuperar/${filtro.codEmpresa}/${filtro.idProceso}`;

        return this.http.get<any>(url, { }).pipe(
            tap(row => this.log('fetched getAvanceProcesosAll')),
            catchError((error) => this.handleError('getAvanceProcesosAll', error))
        );
    }

    getConsultaLogAvanceProcesosList(
                filtro: {
                    idProceso: number,
                    codEmpresa: number
                },
                filter: {
                    value: any,
                    condition: string,
                    column: string
                },
                paginator: MatPaginator,
                sort: MatSort,
                destino: string): Observable<any> {

        let proceso = String(filtro.idProceso);
        let empresa = String(filtro.codEmpresa);

        let params = new HttpParams();
        if( filter.condition != '' ) {
        params = params.append('filtroValor', filter.value);
        params = params.append('filtroCondicion', filter.condition);
        params = params.append('filtroColumna', filter.column);
        params = params.append('filtroRelacion', 'and'); };
        params = params.append('filtroValor', proceso);
        params = params.append('filtroCondicion', '=');
        params = params.append('filtroColumna', 'codigo_proceso');
        params = params.append('filtroRelacion', 'and');
        params = params.append('filtroValor', empresa);
        params = params.append('filtroCondicion', '=');
        params = params.append('filtroColumna', 'codigo_empresa');
        params = params.append('paginaTamano', paginator.pageSize.toString());
        params = params.append('paginaNumero', (paginator.pageIndex + 1).toString());
        params = params.append('ordenColumna', sort.active || 'secuencia');
        params = params.append('ordenTipo', sort.direction || 'desc');

        const sUrl = `${environment.dataServiceUrl}/${destino}/dw_adm_consulta_log_procesos/Pagina`;

        return this.http.get<any>(sUrl, { params }).pipe(
            tap(row => this.log('fetched getConsultaLogAvanceProcesosList')),
            catchError((error) => this.handleError('getConsultaLogAvanceProcesosListList', error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

          // TODO: send the error to remote logging infrastructure
          console.error(result.error); // log to console instead

          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${result.message}`);

          // Let the app keep running by returning an empty result.
          return of(result);
    }

    /** Log a GestionAvanceProcesosService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`GestionAvanceProcesosService: ${message}`);
        console.log(`GestionAvanceProcesosService: ${message}`);
    }

}
