import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';

import { environment } from '../environments/environment';



@Injectable({ providedIn: 'root' })
export class AppService {
  private IdentityRolUrl = '';  // URL to web api
  private SucursalUrl = '';  // URL to web api
  private estructuraUrl = '';  // URL to web api

  constructor(private http: HttpClient) {
    this.IdentityRolUrl = `${environment.dataServiceUrl}/Identity/Rol`;
    this.SucursalUrl = `${environment.dataServiceUrl}/Parametros/Sucursal`;
    this.estructuraUrl = `${environment.dataServiceUrl}/Parametros/Asesor/Estructura`;
  }

  getEstructura(codAsesor: number) {
    const sUrl = `${this.estructuraUrl}/${codAsesor}`;
    return this.http.get<any>(sUrl, {}).pipe(
      tap(() => this.log('getByIdEstructura')),
      catchError((error) => this.handleError('getByIdEstructura', error))
    );
  }

  getByIdIdentityRol(val: any): Observable<any> {
    const sUrl = `${this.IdentityRolUrl}/${val}`;
    return this.http.get<any>(sUrl).pipe(
      retry(3),
      tap(_ => this.log(`getByIdDddwTidentityRol id=${val}`)),
      catchError((error) => this.handleError('getByIdDddwTIdentityRol', error))
    );
  }

  getByIdSucursal(codEmp: any, val: any): Observable<any> {
    const sUrl = `${this.SucursalUrl}/${codEmp}/${val}`;
    return this.http.get<any>(sUrl).pipe(
      retry(3),
      tap(_ => this.log(`getByIdDddwSucursal id=${val}`)),
      catchError((error) => this.handleError('getByIdDddwTSucursal', error))
    );
  }

  private handleError(operation = 'operation', result?: any) {

    // TODO: send the error to remote logging infrastructure
    console.error(result.error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${result.message}`);

    // Let the app keep running by returning an empty result.
    return of(result);
  }

  /** Log a FormaPagoGiroService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`FormaPagoGiroService: ${message}`);
    console.log(`FormaPagoGiroService: ${message}`);
  }

}
