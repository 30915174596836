import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CheckboxValueDirective } from './mat-checkbox-value.directive';
import { EditMaskComponent } from './editmask.component';
import { DataWindowComponent } from './datawindow.component';
import { GroupBoxComponent } from './groupbox.component';
import { RadioButtonComponent } from './radiobutton.component';
import { StaticTextComponent } from './statictext.component';
import { CommandButtonComponent } from './commandbutton.component';
import { WindowComponent } from './window.component';
import { DataWindowDirective } from './datawindow.directive';
import { DataWindowControlComponent } from './datawindowcontrol.component';
import { ControlComponent } from './control.component';
import { DataStoreComponent } from './datastore.component';
import { DataWindowChildComponent } from './datawindowchild.component';
import { MenuComponent } from './menu.component';
import { RectangleComponent } from './rectangle.component';
import { LoadingComponent } from './loading/loading.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  MatSelectModule
} from '@angular/material';

@NgModule({
  declarations: [
    CheckboxValueDirective,
    DataWindowComponent,
    EditMaskComponent,
    GroupBoxComponent,
    RadioButtonComponent,
    StaticTextComponent,
    CommandButtonComponent,
    WindowComponent,
    DataWindowControlComponent,
    ControlComponent,
    DataStoreComponent,
    DataWindowChildComponent,
    DataWindowDirective,
    MenuComponent,
    RectangleComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    CheckboxValueDirective,
    DataWindowComponent,
    EditMaskComponent,
    StaticTextComponent,
    CommandButtonComponent,
    WindowComponent,
    DataWindowControlComponent,
    ControlComponent,
    DataStoreComponent,
    DataWindowChildComponent,
    DataWindowDirective,
    MenuComponent,
    RectangleComponent,
    LoadingComponent    
  ],
  entryComponents:[
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PowerbuilderSharedModule {

  constructor() {
  }
}