import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { httpInterceptorProviders } from './interceptors';
import { appMaterialModule } from './app.material.module';
import { PowerbuilderSharedModule } from './components/powerbuilder.shared.module';
import { AlertasComponent } from './components/alertas.component';
import { AlertasConfirmComponent } from './components/alertasConfirm.component';
import { TableComponent } from './components/table/table.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { VideoPlayerComponent } from './components/videoplayer/videoplayer.component';
import { OnlyNumber } from './directives/onlyNumber.directive';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

window["CONDITIONS_LIST"] = [
  { value: 'like', label: '' },
  { value: 'not like', label: '' },
  { value: '=', label: '' },
  { value: '<>', label: '' }
];

window["CONDITIONS_LIST_NUMBER"] = [
  { value: '>', label: '' },
  { value: '>=', label: '' },
  { value: '<', label: '' },
  { value: '<=', label: '' },
  { value: '=', label: '' },
  { value: '<>', label: '' }
];

@NgModule({
  declarations: [
    AutocompleteComponent,
    AlertasComponent,
    TableComponent,
    AlertasConfirmComponent,
    VideoPlayerComponent,
    OnlyNumber

  ],
  imports: [
    CommonModule,
    appMaterialModule,
    PowerbuilderSharedModule,
    PdfJsViewerModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    AutocompleteComponent,
    appMaterialModule,
    PowerbuilderSharedModule,
    FormsModule,
    PdfJsViewerModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    TableComponent,
    FormsModule,
    VideoPlayerComponent,
    OnlyNumber
  ],
  entryComponents: [
    AutocompleteComponent,
    AlertasComponent,
    AlertasConfirmComponent,
    VideoPlayerComponent
  ],
})
export class SharedModule {

  constructor(private translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('es-mx');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('es-mx');

    window['CONDITIONS_LIST'].map((x) => this.translate.get(`CONDITIONS_LIST.${x.value}`).subscribe((res: string) => {
      x.label = res;
    }));

    window['CONDITIONS_LIST_NUMBER'].map((x) => this.translate.get(`CONDITIONS_LIST_NUMBER.${x.value}`).subscribe((res: string) => {
      x.label = res;
    }));

  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [httpInterceptorProviders, TranslateService]
    };
  }
}
