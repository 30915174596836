import { Component, Input, OnInit } from '@angular/core';
import { moduloService } from '../modulos/modulo.service'
import { moduloModel } from '../modulos/modulo.model'
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  modulos : Array<moduloModel> = [];
  myControl = new FormControl();
  filteredModules: Observable<moduloModel[]>;

  get appComponent() : any {
      return window["appComponent"];
  }

  get usuario(): any {
    var _usuario;
    try {
      _usuario = jwt_decode(sessionStorage.getItem("token"));
    } catch(error) {
      _usuario = {};
    }
    return _usuario;
  }

  constructor(private moduloService: moduloService,
            private router: Router) {
  }

  ngOnInit() {
    this.moduloService.getModulos().subscribe((data) => {
      if(data instanceof Array) {
        this.modulos = data; 
        this.filteredModules = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );    
        this.appComponent.setModulos(data);  
      }
    });
  }

  private _filter(value: string): moduloModel[] {
    const filterValue = value.toString().toLowerCase();
    return this.modulos.filter(_module => _module.moduloNombre.toLowerCase().includes(filterValue));
  }

  selectedModule(module: any) {
    console.log(module);
  }
}
