import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as jwt_decode from 'jwt-decode';

import { environment } from '../../environments/environment';
import { funcionalidadesModel } from './funcionalidades.model';
import { moduloModel } from './modulo.model';

@Injectable({ providedIn: 'root' })
export class funcionalidadesService {
  private rolUrl = '';  // URL to web api
  private notification = '';

  get usuario(): any {
    return jwt_decode(sessionStorage.getItem("token"))
  }

  constructor(private http: HttpClient) {
    this.rolUrl = `${environment.dataServiceUrl}/Identity/Autorizacion/ModuloRolFuncionalidad`;
    this.notification = `${environment.dataServiceUrl}/Mensajeria/Notificacion`;
  }

  getFechaSistema(): Observable<any> {
    const _rolUrl = `${environment.dataServiceUrl}/Parametros/AdministracionDeFecha/FechaDialogo`;
    
    return this.http.get<any>(_rolUrl).pipe(
      tap((data) => this.log(`getFechaSistema`)),
      catchError((error) => this.handleError('getFechaSistema', error))
    );
  }

  getUrlListPermited(rol: number): Observable<any> {
    const _rolUrl = `${this.rolUrl}/${this.usuario.rl}`;

    return this.http.get<funcionalidadesModel[]>(_rolUrl).pipe(
      tap((data) => this.log(`funcionalidadess`)),
      catchError((error) => this.handleError('funcionalidades', error))
    );
  }

  getFuncionalidades(modulo: moduloModel): Observable<any> {
    const _rolUrl = `${this.rolUrl}/${this.usuario.rl}/${modulo.moduloCodigo}`;

    return this.http.get<funcionalidadesModel[]>(_rolUrl).pipe(
      tap((data) => this.log(`funcionalidadess`)),
      catchError((error) => this.handleError('funcionalidades', error))
    );
  }

  filterFuncionalidad_Identity(val: string, rol: number): Observable<any> {
    const params: any = {
      paginaTamano: 10,
      paginaNumero: 1,
      filtroValor: val || ' ',
      ordenColumna: 'funcionalidad_nombre',
      filtroCondicion: 'like',
      filtroColumna: 'funcionalidad_nombre',
      ordenTipo: 'asc'
    };

    const sUrl = `${environment.dataServiceUrl}/Identity/FuncionalidadRol/${rol}/Pagina`;

    return this.http.get<any>(sUrl, { params: params }).pipe(
      tap(_ => this.log(`filterFuncionalidad_Identity id=${val}`)),
      catchError((error) => this.handleError('filterFuncionalidad_Identity', error))
    );
  }

  getNumberNotification() {
    let url = `${this.notification}/Pendientes`;

    return this.http.get(url).pipe(
      tap((data) => this.log(`funcionalidadess`)),
      catchError((error) => this.handleError('funcionalidades', error))
    );
  }

  listNotification(usuario: string) {
    let params = new HttpParams();
    params = params.append('paginaNumero', '1');
    params = params.append('paginaTamano', '12');
    params = params.append('ordenTipo', 'asc');
    params = params.append('filtroColumna', 'usuario_to');
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroValor', usuario);
    params = params.append('filtroValor', "0");
    params = params.append('filtroCondicion', '>');
    params = params.append('filtroColumna', 'secuencia');
    params = params.append('filtroRelacion', 'and');
    params = params.append('filtroValor', "0");
    params = params.append('filtroCondicion', '=');
    params = params.append('filtroColumna', 'estado');
    params = params.append('filtroRelacion', 'and');
    params = params.append('ordenColumna', 'secuencia desc, estado');

    const sUrl = `${this.notification}/Pagina`;
    
    return this.http.get<any>(sUrl, { params }).pipe(
      tap(row => this.log('fetched CarpetaReferenciasPreguntas')),
      catchError((error) => this.handleError('getCarpetaReferenciasPreguntasList', error))
    );
  }

  private handleError(operation = 'operation', result?: any) {

    // TODO: send the error to remote logging infrastructure
    console.log(result.error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${result.message}`);

    // Let the app keep running by returning an empty result.
    return of(result);
  }

  /** Log a INVCO_BienesService message with the MessageService */
  private log(message: string) {
    // this.messageService.add(`loginService: ${message}`);
    console.log(`funcionalidadesService: ${message}`);
  }

}
