import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from '../components/loading/loading.service'
import { version } from '../../../package.json';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    public _LoaderService: LoadingService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    let authToken = sessionStorage.getItem('token');

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    let authReq: any;
    let newUrl = '';
    if (req.url.indexOf("amazonaws") <= 0) {
      if (authToken && authToken.length > 10) {

        switch (true) {
          case req.url.startsWith(environment.dataServiceUrl):
            /*const api = req.url.replace(environment.dataServiceUrl, '').split('/')[1].toLowerCase();
            newUrl = req.url.replace(/(:[0-9]+)/, ':' + environment[`${api}ApiPort`]);*/
            newUrl = req.url;
            break;
          default:
            let sSeparator = newUrl.indexOf("?") > 0 ? '&' : '?';
            newUrl = `${req.url}${sSeparator}_v=${version}`;
            break;
        }
        authReq = req.
          clone({
            url: newUrl,
            setHeaders: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
            }
          });

      } else {
        authReq = req.clone();
      }
    } else {
      authReq = req.clone();
    }

    // send cloned request with header to the next handler.
    this._LoaderService.showLoading();
    return next.handle(authReq).pipe(

      tap(
        // Succeeds when there is a response; ignore other events
        event => {
          if (event instanceof HttpResponse) {
            authToken = event.headers.get('Authorization');
            if (authToken) {
              sessionStorage.setItem('token', authToken);
            }
          }
          return event;
        },

        // Operation failed; error is an HttpErrorResponse
        error => error

      ),
      finalize(() => this._LoaderService.hideLoading())
    );

  }
}
