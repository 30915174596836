import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, HostListener } from '@angular/core';

const itemsPerPage = 'Items per page:';
const nextPage = 'Next page';
const prevPage = 'Previous page';
const firstPage = 'First page';
const lastPage = 'Last page';
let of = 'of';

@Injectable()
export class MatPaginatorI18nService extends MatPaginatorIntl {

    _mostrar: boolean = true;

    public constructor(private translate: TranslateService) {
        super();
        this.onResize(event);

        this.translate.onLangChange.subscribe((e: Event) => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    public getRangeLabel = (page: number, pageSize: number, length: number): string => {

        // if (this._mostrar) {
            if (length === 0 || pageSize === 0) {
                return `0 ${of} ${length}`;
            };
            length = Math.max(length, 0);
            const startIndex: number = page * pageSize;
            const endIndex: number = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;            
            return `${startIndex + 1} - ${endIndex} ${of} ${length}`;
        
        // } else {
        //     return '';
        // };
    };

    public getAndInitTranslations(): void {
        this.translate.get([ "paginacion.itemsPerPage", "paginacion.nextPage", "paginacion.prevPage", "paginacion.firstPage", "paginacion.lastPage", "paginacion.of" ])
            .subscribe((translation: any) => {
                this._mostrar ? this.itemsPerPageLabel = translation["paginacion.itemsPerPage"] : this.itemsPerPageLabel = '';
                this.nextPageLabel = translation["paginacion.nextPage"];
                this.previousPageLabel = translation["paginacion.prevPage"];
                this.firstPageLabel = translation["paginacion.firstPage"];
                this.lastPageLabel = translation["paginacion.lastPage"];
                of = translation["paginacion.of"];
                this.changes.next();
            });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth < 550) {
            this._mostrar = false;
        } else {
            this._mostrar = true;
        };
    }

}
