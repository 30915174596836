import { HttpClient, HttpParams } from "@angular/common/http";
import { catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";

import { environment } from "../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class AutocompleteService {

    constructor(private http: HttpClient) {}

    filtroAutocomplete(val: string, url: string, columnaFiltro: string, filtroAdicional: any[]): Observable<any> {
        const sUrl = `${environment.dataServiceUrl}/${url}/Pagina`;

        let params = new HttpParams();
        if (val !== undefined && val !== null && val !== '') {
        params = params.append('filtroValor', val);
        params = params.append('filtroCondicion', 'like');
        params = params.append('filtroColumna', columnaFiltro); };

        filtroAdicional.forEach(x => {
        params = params.append('filtroRelacion', 'and');
        params = params.append('filtroValor', x.valor);
        params = params.append('filtroCondicion', x.condicion);
        params = params.append('filtroColumna', x.columna); });

        params = params.append('paginaTamano', '10');
        params = params.append('paginaNumero', '1');
        params = params.append('ordenColumna', columnaFiltro);
        params = params.append('ordenTipo', 'asc');

        return this.http.get<any>(sUrl, {params: params}).pipe(
            tap(_ => this.log(`filterAutocomplete val=${val}`)),
            catchError((error) => this.handleError(`filterAutocomplete`, error))
        );
    }

    private handleError(operation = 'operation', result?: any) {

        // TODO: send the error to remote logging infrastructure
        console.error(result.error); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.log(`${operation} failed: ${result.message}`);

        // Let the app keep running by returning an empty result.
        return of(result);
    }

    /** Log a AutocompleteService message with the MessageService */
    private log(message: string) {
        // this.messageService.add(`AutocompleteService: ${message}`);
        console.log(`AutocompleteService: ${message}`);
    }
}