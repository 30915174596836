import { Component } from '@angular/core';
import { ConfirmarCorreoClienteService } from './confirmarcorreocliente.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar, MatBottomSheet } from '@angular/material';


import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
    selector: 'emailConfirmComp',
    templateUrl: './confirmarcorreocliente.html',
    styleUrls: ['./confirmarcorreocliente.css'],
    providers: [ConfirmarCorreoClienteService]
})
export class ConfirmarCorreoCliente {

    @BlockUI() blockUI: NgBlockUI;

    info: string;
    resultado: string;
    _proc = false;

    constructor(public dialog: MatDialog,
        private route: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private servicio: ConfirmarCorreoClienteService) {
        this.resultado = "...";
    }

    ngOnInit() {
        this.resultado = "Verificando Correo Electrónico...";
        this.route
            .queryParamMap.subscribe((params: any) => {
                this.info = params.params.info;
                sessionStorage.setItem('token', this.info);
                this.servicio.confirmar(this.info).subscribe((data: any) => {
                    if (data === true) {
                        this.resultado = "Correo Electrónico Verificado Correctamente."
                    } else {
                        if (data.message) {
                            this.resultado = "Proceso Realizado Anteriormente";
                        } else {
                            this.resultado = "Proceso Realizado Anteriormente";
                        }
                    }
                });
            });
    }

    openNotificationDanger(message: string, action?: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
            panelClass: 'dangerSnackBar',
        });
    }

}
