import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatBottomSheet } from '@angular/material';
import { RecuperaraccesoService } from './recuperaracceso.service';

@Component({
  templateUrl: './recuperaracceso.dialog.html',
  styleUrls: ['./recuperaracceso.dialog.css'],
  providers: [RecuperaraccesoService]
})
export class RecuperarAccesoDialog implements OnInit {
  recuperarAccesoForm: FormGroup;
  _proc = false;
  _status = false;
  resultError: string = null;
  recaptchaValid = true; // TODO set to False when activate captcha
  solicitarResponse;
  selectedMethod;
  isRecovering = false;
  recovered = false;
  appPortalId = 1;
  siteKey = '6LeKLQcTAAAAAEhjrZqAgZctZc5WnwlyqIYZrcOP';
  constructor(private builder: FormBuilder,
    private recuperaraccesoService: RecuperaraccesoService,
    private _snackBar: MatSnackBar,
    private _bottomSheet: MatBottomSheet,
    public dialogRef: MatDialogRef<RecuperarAccesoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // this.user = data;
  }
  ngOnInit() {
    this.recuperarAccesoForm = this.builder.group({
      'username': ['', [Validators.required]],
    }, {});
  }
  // resolved(captchaResponse: string) {
  //   this.recaptchaValid = false;
  //   if (captchaResponse) {
  //     this.recaptchaValid = true;
  //   } else {
  //     this.openNotificationDanger('reCaptcha Error');
  //   }

  // }
  solicitar() {
    this.solicitarResponse = null;
    this.recuperaraccesoService.verificarUsuario(
      this.recuperarAccesoForm.value.username).subscribe(rst => {
        if (rst && rst.estado === 200) {
          this.solicitarResponse = rst;
        } else {
          let error = 'error';
          if (rst.mensaje) {
            error = rst.mensaje;
          }else if (rst.message) {
            error = rst.message.mensaje; 
          }
          this.openNotificationDanger(error);
        }
      });
  }
  recuperar() {
    this.isRecovering = true;
    this.recovered = false;
    this.recuperaraccesoService.recuperarAcceso(
      this.recuperarAccesoForm.value.username,
      this.selectedMethod,
      this.appPortalId,
    ).subscribe(rst => {
      if (rst && rst.estado === 200) {
        this.recovered = true;
      }
    });
  }
  openNotificationDanger(message: string, action?: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'dangerSnackBar',
    });
  }
}
