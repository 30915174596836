import { Injectable } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TableService {
    constructor(
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe
        ) {}

    setCurrencyPipe(val:number){
        return this.currencyPipe.transform(val, '$');
    }
    setDatePipe(val:Date, dateFormat?:string){
        let format = 'dd/MM/yyyy';
        if(dateFormat){
            format = dateFormat;
        }
        return this.datePipe.transform(val, format);
    }
}
