import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      var error = err.error || err.message || err.statusText;
      if (err.status === 200) { return }
      if (err.status === 401 || err.status === 412 || err.status === 403 /*|| err.status == 0*/) {
        if (sessionStorage.getItem('isLogin')) {//loged
          const errors = { status: err.status, message: err.error.message, error: true };
          return throwError(errors);
        } else { // notLoged
          // if (!this.isOpenDialog) {
          //   this.loginDialog();
          // }else{
          //   const errors = { status: err.status, message: err.error.message, error: true };
          //   return throwError(errors);
          // }
          // sessionStorage.removeItem("token");
          // sessionStorage.removeItem("isLogin");
          const errors = { status: err.status, message: err.error.message, error: true };
          return throwError(errors);
        }
      } else if (err.status === 409) {
        const errors = { error: err.error, message: err.error, status:err.status };
        return throwError(errors);
      }
      return throwError(error);
    }));
  }
}
