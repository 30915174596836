import { Component, EventEmitter, Input, Output } from "@angular/core";
import { debounceTime, startWith, switchMap } from "rxjs/operators";
import { FormControl, Validators } from "@angular/forms";

import { DialogValidators } from '../validators.dialog';
import { AutocompleteService } from "./autocomplete.service";

@Component({
    selector: 'autocomplete-component',
    templateUrl: './autocomplete.component.html',
    providers: [AutocompleteService]
})
export class AutocompleteComponent {

    private _required: boolean = false;
    private _reset: boolean = false;
    private _init: boolean = true;
    
    @Input() filtroAdicional: FiltroAdicionalModel[] = [];
    @Input() columnaFiltro: string;
    @Input() placeholder: string;
    @Input() optNombre: string;
    @Input() optCodigo: string;
    @Input() url: string;
    @Input() msg: boolean = true;

    @Input() set nombreInicial(valor: string) {
        if (valor !== undefined && valor !== '') {
            this._reset = true;
            this.nombreCtrl["selected"] = true;
            this.nombreCtrl.reset(valor);
        };
    };

    @Input() set required(estado: boolean) {
        if (estado) {
            this._required = true;
            this.nombreCtrl.setValidators([Validators.required, DialogValidators.autocomplete]);
        } else {
            this.nombreCtrl.setValidators([DialogValidators.autocomplete]);
        };
    };

    @Input() set disabled(estado: boolean) {
        if (estado) {
            this.nombreCtrl.disable({ emitEvent: false });
            this.nombreCtrl.reset('', { emitEvent: false });
        } else {
            this.nombreCtrl.enable();
        };
    };

    @Input() set reset(valor: string) {
        this._reset = true;
        valor = valor === null || valor === undefined ? '' : valor;
        this.nombreCtrl.reset(valor);
    }
    
    @Output() codigo = new EventEmitter<number>();
    @Output() nombre = new EventEmitter<string>();
    @Output() opt = new EventEmitter<any>();

    nombreCtrl: FormControl = new FormControl();
    filteredListaDatos: Array<any> = [];

    constructor(private autocompleteService: AutocompleteService) {}

    ngOnInit() {
        if (!this._reset) { this.nombreCtrl.setValue('') };
        this.nombreCtrl.valueChanges.pipe(
            startWith(''),
            debounceTime(1000),
            switchMap((data) => {
                data === '' && !this._init ? this.codigo.emit(null) : this._init = false;
                return this.autocompleteService.filtroAutocomplete(data, this.url, this.columnaFiltro, this.filtroAdicional)
            })
        ).subscribe((data) => this.filteredListaDatos = data.rows);
        if (!this._required) { this.nombreCtrl.setValidators([DialogValidators.autocomplete]) };
    }

    onKeydownAutocomplete(event: any) {
        if (AutocompleteComponent.validarTecla(event)) {
            this.nombreCtrl["selected"] = false;
            this.codigo.emit(-1);
            this.nombre.emit('');
            this.opt.emit(null);
        };
    }

    onSelectAutocomplete(opt: any) {
        this.nombreCtrl["selected"] = true;
        this.codigo.emit(opt[this.optCodigo]);
        this.nombre.emit(opt[this.optNombre]);
        this.opt.emit(opt);
    }

    static validarTecla(event: any): boolean {
        let val = ["Escape", "Tab", "Shift", "ArrowLeft", "ArrowRight", "Enter", "Alt", "Control", "Home", "End", "Delete", "Insert", "PageUp", "PageDown", "NumLock", "ScrollLock", "Pause", "Meta", "ContextMenu", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12"]
            .find(x => x === event.key);
        return val === undefined;
    }
}

export class FiltroAdicionalModel {
    public valor: string;
    public condicion: string;
    public columna: string;
}