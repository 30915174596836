import { AfterViewInit, Component, HostBinding, OnInit, OnDestroy, Input, ViewChild, AfterContentInit } from '@angular/core';
import { PowerObjectComponent } from './powerobject.component';
@Component({
    selector: 'control',
    template: '<div><h6>hola</h6></div>'
})
export class ControlComponent extends PowerObjectComponent implements OnInit, OnDestroy {
    public TypeOf: string = "control";
    public ConstructorCompleted: boolean = false;

    constructor() { 
        super();
    }

    @HostBinding('style.background-color') public backcolor: any;
    public border: any;
    @HostBinding('style.border-color') public bordercolor: any;
    public borderstyle: any;
    public className = 'control';
    public control: Array<any> = [];
    public bringtotop: boolean;
    
    public enabled: boolean;
    public hscrollbar: boolean;
    @Input('id') public name: string = null;
    @Input('parent') public parent: any;
    @Input('tabsequence')     
    set tabsequence(value: number) {
        this._readonly = (value === 32766);
        this._tabsequence = value;
    }

    get tabsequence() {
        return this._tabsequence;
    }

    @Input()
    set modifies(oCol: any) {
        if(oCol) {
            for(var sProp in oCol) {
                switch(sProp) {
                    case 'name':
                    case 'id':
                        break;
                    default:
                        this[sProp] = oCol[sProp];    
                        break;
                }
            }
        }
    }

    public parentwindow: any;
    public pointer: any;
    public taborder: number = 1;
    public tag: any;
    public textcolor: any;
    public vscrollbar: boolean;

    _cursor: string = "";
    _tabsequence: number = 0;

    //@HostBinding('readonly')
    _readonly: boolean;

    @HostBinding('style.display') _visible: string = "block";

    @Input()
    set visible(value: any) {
       this._visible = (value === '1' || value === 1 || value === true )?"block":"none";     
    }

    get visible(): any {
        return (this._visible === "block")?'1':'0';
    }

    fontpitch: string;
    fontfamily: string;
    fontcharset: string;
    italic: boolean;
    weight: any;
    textsize: any;
    facename: any;

    @HostBinding('style.left.px') _left: number;
    @HostBinding('style.top.px') _top: number;
    @HostBinding('style.width.px') _width: number;
    @HostBinding('style.height.px') _height: number;



    _scalex: number = 4.53;
    _scaley: number = 4.0;

    public caption: string = "";

    @Input('text') 
    public set text(val: string) {
        this.caption = val;
    }

    public get text(): string {
        return this.caption;
    }

    get x(): number {
        return (this._left || 0) * this._scalex;
    }

    @Input()
    set x(value: number) {
        this._left = (value || 0) / this._scalex;
    } 

    get y(): number {
        return (this._top  || 0) * this._scaley;
    }

    @Input()
    set y(value: number) {
        this._top = (value || 0) / this._scaley;
    } 

    get width(): number {
        return ( this._width || 0) * this._scalex;
    }

    @Input()
    set width(value: number) {
        this._width = ( value || 0) / this._scalex;
    } 

    get height(): number {
        return (this._height || 0) * this._scaley;
    }

    @Input()
    set height(value: number) {
        this._height = (value || 0) / this._scaley;
    } 

    public ngOnInit() {

    }

    public ngAfterViewInit() {
        this.on_constructor();
    }

    public ngOnDestroy() {
        this.on_destructor();
    }

    public on_clicked(...args: Array<any>): any {
        console.error("control - clicked: Not Implemented");
        return null;
    }

    public deleteobject(arg0: any): any {
        throw new Error("Method not implemented.");
    }

    public getparent(): any {
        return this.parent;
    };

    public hide(): boolean {
        this.visible = false;
        return this.visible;
    };

    public move(...args: Array<any>): any {
        throw new Error("Method not implemented.");
    };

    pointery(): any {
        throw new Error("Method not implemented.");
    }

    pointerx(): any {
        throw new Error("Method not implemented.");
    }

    public postevent(...args: Array<any>): any {
        let fx_event = this["on_" + args[0]];
        let __this = this;
        setTimeout(() => {
            if(fx_event) {
                return fx_event.apply(__this);
            }
        })
        return null;
    };

    public resize(arg1: number, arg2: number): boolean {
        return true;
    };

    public selectobject(arg1: number, arg2: number): any {
        throw new Error("Method not implemented.");
    };

    public setfocus(): boolean {
        return true;
    };

    public setpointer(arg1: any): any {
        this._cursor = arg1;
    };

    public setredraw(arg1: boolean): boolean {
        return true;
    };

    public show(): boolean {
        this.visible = true;
        return this.visible;
    };

}
