import { Component, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { TableService } from './table.service';
import Utils from '../../services/utilidades';
import { MatSort } from '@angular/material';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;

@Component({
  selector: 'table-component',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  providers: [TableService]
})
export class TableComponent implements AfterViewInit {

  @Output() onApplyFilter = new EventEmitter<any>();
  @Output() onClearColumn = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() onSelectAndEdit = new EventEmitter<any>();
  @Output() matSortEvent = new EventEmitter<any>();
  rows: any;
  @Input() set setRows(val: any) {
    console.log('set rows: ', val);
    this.rows = [...val];
  }
  @Input() columns: any[];
  @Input() tableParams: any = [];

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  originals: any[] = [];
  selectedRow: any;
  selectedIndex: number = 0;
  originalRow: any;
  displayedColumns;
  // tableHeight: any[];

  public conditionsList = CONDITIONS_LIST;
  public conditionsListNumber = CONDITIONS_LIST_NUMBER;
  public searchValue: any = {};
  public searchCondition: any = {};
  public _pageSize = 10;

  filter = {
    column: '',
    condition: '',
    value: ''
  };

  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  utils = Utils;

  _proc: boolean = false;
  _status: boolean = false;
  resultError: string = null;

  constructor(private tableService: TableService) { }

  ngAfterViewInit() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
  }
  onSelectFunc(event: Event, row: any, index: number) {
    this.selectedRow = row;
    this.selectedIndex = index;
    let rst = { 'event': event, 'row': row, 'index': index };
    this.onSelect.emit(rst);
  }
  onSelectAndEditFunc(event, row: any, index: number) {
    this.selectedRow = row;
    this.selectedIndex = index;
    let rst = { 'event': event, 'row': row, 'index': index };
    this.onSelectAndEdit.emit(rst);
  }
  onApplyFilterFunc(e: Event, columnDB: string, columnModel: string) {
    let rst = { 'event': e, 'columnDB': columnDB, 'condition': this.searchCondition[columnModel], 'value': this.searchValue[columnDB] };
    this.onApplyFilter.emit(rst);
  }
  onClearColumnFunc(columnModel: string) {
    this.onClearColumn.emit(columnModel);
  }

  sortChange() {
    let result = this.columns.filter(rows => rows.columnDef == this.sort.active);
    let newSort = new MatSort;
    newSort.active = result[0].columnDefSnake;
    newSort.direction = this.sort.direction;
    this.matSortEvent.emit(newSort);
  }

}
