import { Component, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatSort, PageEvent, MatDialog, MatSnackBar, MAT_DIALOG_DATA, MatDialogRef, MatBottomSheet } from '@angular/material';
import { catchError, map, startWith, switchMap, timeout } from 'rxjs/operators';
import { merge, of as observableOf } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { AlertasConfirmComponent } from 'src/app/components/alertasConfirm.component';
import { GestionAvanceProcesosDialog } from './gestionavanceprocesos.dialog';
import { GestionAvanceProcesosService } from './gestionavanceprocesos.service';
import { GestionAvanceProcesosModel } from './gestionavanceprocesos.model';

declare var CONDITIONS_LIST: any;
declare var CONDITIONS_LIST_NUMBER: any;
declare var appComponent: any;

@Component({
  selector: 'gestionavanceprocesos-table',
  templateUrl: './gestionavanceprocesos.table.html',
  styles: [
    '#descripcion_t { text-align: left; flex: 0 0 300px; }',
    '#descripcion { text-align: left; flex: 0 0 280px; }',
  ],
  providers: [GestionAvanceProcesosService]
})
export class GestionAvanceProcesosTable {
    rows: GestionAvanceProcesosModel[] = [];
    selectedRow: GestionAvanceProcesosModel;
    selectedIndex: number = 0;
    destino: string;

    idInterval: any;
    progreso: number = 0;
    procesosRealizados: number = 0;
    totalProcesos: number = 0;
    complete: boolean = false;
    errorProceso: boolean = false;
    mensajeRespuesta: string = '';

    public displayedColumns: string[] = ['codigo_proceso', 'nombre_evento', 'descripcion', 'codigo_retorno', 'usuario', 'fecha_evento', 'fecha_negocio'];

    public conditionsList = CONDITIONS_LIST;
    public conditionsListNumber = CONDITIONS_LIST_NUMBER;
    public searchValue: any = {};
    public searchCondition: any = {};
    public _pageSize = 10;

    filter = {
      column: '',
      condition: '',
      value: ''
    };

    selectedColumn = {
      name: '',
      dbName: '',
      type: '',
      sign: '',
      filter: []
    };

    filtro = {
      idProceso: 0,
      codEmpresa: 0
    };

    resultsLength = 0;
    isLoadingResults = false;
    isRateLimitReached = false;

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: false}) sort: MatSort;
    @BlockUI() blockUI: NgBlockUI;

    _proc: boolean = false;
    _status: boolean = false;
    resultError: string = null;

    constructor(public dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _bottomSheet: MatBottomSheet,
                private gestionAvanceProcesosService: GestionAvanceProcesosService,
                public dialogRef: MatDialogRef<GestionAvanceProcesosTable>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.dialogRef.disableClose = true;
        this.filtro.idProceso = data.idProceso;
        this.filtro.codEmpresa = Number(appComponent.usuario.mpr);
        this.destino = data.destino === undefined ? 'Identity' : data.destino;
    }

    ngAfterViewInit() {
      setTimeout(() => {
        this.onConsultarLog();
        this.onCalcularAvance();
      }, 5000);
    }

    onCalcularAvance() {
      this.gestionAvanceProcesosService.getAvanceProcesosAll(this.filtro, this.destino).subscribe((data: any) => {
        this._status = !!data.error;
        this.resultError = null;

        if (!this._status) {

          if (data.rows.length > 0) {
            this.procesosRealizados = data.rows[0].registroActual;
            this.totalProcesos = data.rows[0].totalRegistros;
            this.mensajeRespuesta = data.rows[0].mensajeActual;

            if (this.procesosRealizados === 0 && this.totalProcesos === 0) {
              this.progreso = 100;
              this.errorProceso = true;
            } else {
              this.progreso = ( (this.procesosRealizados/this.totalProcesos) * 100 );
            };
          };

          this.onValidarProgreso();

        } else {
          this.resultError = data.message;
          this.openNotificationDanger(this.resultError);
        };
      })
    }

    onValidarProgreso() {
      setTimeout(() => {
        this.onConsultarLog();
        if (this.progreso < 100) {
          this.onCalcularAvance();

        } else {
          this.complete = true;
          const sheetRef = this._bottomSheet.open(AlertasConfirmComponent, {
            data: { tipoMensaje: 20 }
          });

          sheetRef.afterDismissed().subscribe(dataDismised => {
            if (dataDismised.mensaje === 'accept') {
              this.onCloseDialog();
            };
          })
        };
      }, 2000);
    }

    onConsultarLog() {
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
          .pipe(
            startWith({}),
            switchMap(() => {
              let sortExpr = '';
              if (this.sort.active) {
                sortExpr = `${this.sort.active} ${this.sort.direction}`;
              }
              return this.gestionAvanceProcesosService.getConsultaLogAvanceProcesosList(
                    this.filtro,
                    this.filter,
                    this.paginator,
                    this.sort,
                    this.destino);
            }),
            map((data: any) => {
              // Flip flag to show that loading has finished.
              this.isRateLimitReached = false;
              this.resultsLength = data.count;
              return data.rows;
            }),
            catchError(() => {
              // Catch if the API has reached its rate limit. Return empty data.
              this.isRateLimitReached = true;
              return observableOf([]);
            })
          ).subscribe((data: any) => {
            this.rows = [...data];
          });
    }

    onCloseDialog() {
      clearInterval(this.idInterval);
      this.dialogRef.close({
        complete: this.complete,
        errorProceso: this.errorProceso
      });
    }

    onRefresh() {
      this.filter.column = '';
      this.filter.value = '';
      this.filter.condition = '';

      this.onConsultarLog();
    }

    onChangePage() {
      this.onConsultarLog();
    }

    onSelect(event: Event, row: GestionAvanceProcesosModel, index: number) {
        this.selectedRow = row;
        this.selectedIndex = index;
    }

    onSelectColumn(name: string, columnType: string, dbName: string, sign: string) {
      this.selectedColumn.name = name;
      this.selectedColumn.dbName = dbName;
      this.selectedColumn.type = columnType;
      this.selectedColumn.sign = sign;
      this.selectedColumn.filter = (columnType === 'number') ? CONDITIONS_LIST_NUMBER : CONDITIONS_LIST;
    }

    onSelectAndEdit(event, row: GestionAvanceProcesosModel, index: number) {
      this.selectedRow = row;
      this.selectedRow._estado = 'O';
      this.selectedIndex = index;

      this.openDialog();
    }

    onApplyFilter(e: Event) {
      this.filter.column = this.selectedColumn.dbName;
      this.filter.condition = this.searchCondition[this.selectedColumn.name];
      this.filter.value = this.searchValue[this.selectedColumn.name];

      let evt = new PageEvent();
      evt.pageIndex = 0;
      this.paginator.page.emit(evt);
    }

    onClearColumn(e: Event) {
      this.searchValue[this.selectedColumn.name] = '';
      this.searchCondition[this.selectedColumn.name] = '';
      this.onRefresh();
    }

    onExportCsv(e: Event) { }

    onTotals(data: any) {
      Object.assign(this.selectedRow, data);
    }

    openDialog(): void {
      const dialogRef = this.dialog.open(GestionAvanceProcesosDialog, {
        data: {
          selected: this.selectedRow
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          Object.assign(this.selectedRow, result.data);
          switch (this.selectedRow._estado )
          {
            case 'N':
              this.selectedRow._estado = 'O';
              this.selectedIndex = this.rows.length;
              this.rows.push(this.selectedRow);
              this.rows = [...this.rows];
              this.resultsLength = this.rows.length;
              break;
            case 'D':
              this.rows.splice(this.selectedIndex, 1);
              this.rows = [...this.rows];
              this.selectedIndex = -1;
              this.selectedRow = null;
              break;
          }
        }
      });
    }

    openNotificationDanger(message: string, action?: string) {
      this._snackBar.open(message, action, {
          duration: 2000,
          panelClass: 'dangerSnackBar',
      });
   }
}
